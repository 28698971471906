.b-language-changer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 1.2rem;
  padding-bottom: 0;

  &__languages {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 5px;
  }

  &__item {
    width: 2rem;
    filter: grayscale(60%);
    opacity: 0.6;
    transition: $global-transition;
  }

  &__item:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
}
