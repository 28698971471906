.c-section-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__title {
    margin-bottom: 2.4rem;
  }
}
