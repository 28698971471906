.b-content-card-with-emblem {
  border: 1px solid map-get($colors, primary);
  border-image: map-get($gradients, gold) 1;
  padding: map-get($spacing, small);
  margin-bottom: $spacing-vertical-mobile;
  position: relative;

  &__emblem {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  @include list-styling();

  @include bp(medium) {
    padding: map-get($spacing, medium);
  }
}
