/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

@mixin h1() {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 900;

  @include bp(medium) {
    font-size: 4.2rem;
    line-height: 4.2rem;
  }
}

h1 {
  @include h1();
}

h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 400;

  @include bp(medium) {
    font-size: 3.6rem;
    line-height: 3.6rem;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 400;

  @include bp(medium) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}
