// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap');

$font-sans-serif: 'Lato', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

$spacing: (
  small: 1.6rem,
  medium: 2.4rem,
  large: 3.2rem,
  xlarge: 6.4rem,
);

$spacing-vertical-mobile: 3.2rem;
$spacing-vertical-desktop: 6.4rem;

/* Colors
   ========================================================================== */
$brand-colors: (
  gold: #f1cd05,
  // blue: #0a1f8f,
  blue: #2e2684,
);

$colors: (
  white: #fff,
  black: #000,
  gray: #666,
  primary: map-get($brand-colors, gold),
  secondary: map-get($brand-colors, blue),
);

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

// Gradients
/* stylelint-disable */
$gradients: (
  gold:
  linear-gradient(
      135deg,
      #af8539 0%,
      #fed05e 23.46%,
      #edbe4f 31.89%,
      #e2b245 40.86%,
      #deaf42 50.44%,
      #fdcf5c 76.91%,
      #a87a28 100%),


  gold-horizontal:
  linear-gradient(
      180deg,
      #af8539 0%,
      #fed05e 23.46%,
      #edbe4f 31.89%,
      #e2b245 40.86%,
      #deaf42 50.44%,
      #fdcf5c 76.91%,
      #a87a28 100%),

  blue: linear-gradient(
      135deg,
      darken(map-get($colors, secondary), 5%) 0%,
      map-get($colors, secondary) 50%,
      darken(map-get($colors, secondary), 5%) 100%
  )
);


@mixin gradient-text($gradientName) {
  background: map-get($gradients, $gradientName);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

@mixin gold-background() {
  background: map-get($gradients, gold);
}

@mixin background-gradient-silver() {
  background: linear-gradient(
      60deg,
      rgba(248, 248, 248, 1) 0%,
      rgba(238, 238, 238, 1) 27%,
      rgba(233, 233, 233, 1) 29%,
      rgba(221, 221, 221, 1) 65%,
      rgba(248, 248, 248, 1) 68%,
      rgba(248, 248, 248, 1) 100%
  );
}

@mixin card-shadow() {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

/* stylelint-enable */
