.b-content-card-table {
  border: 1px solid map-get($colors, primary);
  border-image: map-get($gradients, gold) 1;
  padding: map-get($spacing, medium);
  margin-bottom: $spacing-vertical-mobile;
  position: relative;
  font-size: 1.6rem;

  @include text-small();
  @include list-styling();

  &__ingredient {
    border-bottom: 1px solid #e5e5e5;

    &--primary {
      font-weight: 500;

      td {
        padding-top: 1rem;
      }
    }

    &--secondary {
      color: #999;
    }
  }
}
