.b-testimonials {
  background: map-get($gradients, blue);
  position: relative;
  overflow: hidden;

  &__nav {
    position: absolute;
    top: 50%;
    opacity: 0.8;
    transition: $global-transition;
    transform: translateY(-50%);

    @include gradient-text(gold);

    font-size: 3rem;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }

    &--prev {
      left: 6.4rem;
    }

    &--next {
      right: 6.4rem;
    }
  }
}

.b-testimonials-items {
  display: flex;
  flex-direction: row;
}

.c-testimonial {
  flex-shrink: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  transition: $global-transition;

  &__content {
    font-style: italic;

    @include text-big();
  }

  &__signature {
    margin-bottom: 3.2rem;
  }

  &.swiper-slide-next,
  &.swiper-slide-prev {
    opacity: 0;
  }
}
