.b-inset-card {
  z-index: 10;
  padding-bottom: 0;

  &__inner {
    padding: map-get($spacing, medium);
    background-color: map-get($colors, secondary);
    z-index: 10;
    transform: translateY(map-get($spacing, large));
    border-inline: 1px solid map-get($colors, primary);

    @include card-shadow();
  }

  &__content {
    @include text-big();

    text-align: center;
    color: #fff;
    padding: map-get($spacing, medium);
    border: 1px solid map-get($colors, primary);
    border-image: map-get($gradients, gold) 1;
  }
}
