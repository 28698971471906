.c-info-circles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  gap: 0 map-get($spacing, 'small');

  @include bp-down(small) {
    @include text-small();
  }
}

.c-info-circles__item {
  position: relative;
  width: 40%;

  @include bp(medium) {
    width: 30%;
  }

  @include bp(large) {
    width: 20%;
  }

  svg {
    stroke-width: 2;
  }
}

.c-info-circles__item-inner {
  padding: 0 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-info-circles__item-title {
  font-weight: 700;

  @include gradient-text(gold);
}

.c-info-circles__item-text {
  @include text-small();
}
