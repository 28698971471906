.b-extendable-content {
  background-color: map-get($colors, secondary);
  color: #fff;

  &__title {
    text-align: center;
    margin-bottom: 3.2rem;
  }

  &__content {
    max-height: 250px;
    overflow: hidden;
    transition: $global-transition;
    position: relative;
    margin-bottom: 3.2rem;

    &::before {
      transition: $global-transition;
      display: block;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: linear-gradient(
        0deg,
        map-get($colors, secondary) 20%,
        rgba(map-get($colors, secondary), 0) 100%
      );
      height: 100px;
    }
  }

  .b-extendable-content__button--extend {
    opacity: 1;
    max-height: 1000px;
  }

  .b-extendable-content__button--collapse {
    opacity: 0;
    max-height: 0;
  }

  &--white {
    background-color: #fff;
    color: #666;

    .b-extendable-content__content {
      max-height: 120px;

      &::before {
        background: linear-gradient(
          0deg,
          map-get($colors, white) 20%,
          rgba(map-get($colors, white), 0) 100%
        );
        height: 100px;
      }
    }
  }

  &--extended {
    .b-extendable-content__button--extend {
      opacity: 0;
      max-height: 0;
    }

    .b-extendable-content__button--collapse {
      opacity: 1;
      max-height: 1000px;
    }

    .b-extendable-content__content {
      max-height: 30000px;

      &::before {
        opacity: 0;
      }
    }
  }
}
