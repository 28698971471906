.b-image-info {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  padding-bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  padding-top: 9.6rem;

  @include bp(xlarge) {
    background-size: contain;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding-bottom: 6.4rem;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    padding-left: 0;

    @include bp(large) {
      padding-left: 40%;
    }
  }
}
