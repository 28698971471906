.b-icons-grid {
  display: flex;
  flex-direction: column;

  @include text-small();

  align-items: center;
  background-color: #f5f5f5;

  @include bp(large) {
    flex-direction: row;
    justify-content: space-between;
    gap: 6.4rem;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include bp(large) {
      width: 40%;
    }
  }

  &__icon {
    width: 25%;

    @include bp(large) {
      width: 33.33%;

      &:last-child {
        margin: 0 auto;
      }
    }
  }

  &__ingredients {
    @include card-shadow();

    background-color: #fff;
    padding: 2.4rem;
    border: 1px solid map-get($colors, primary);
    border-image: map-get($gradients, gold) 1;
    margin-bottom: $spacing-vertical-mobile;

    h3 {
      @include gradient-text(gold);
    }

    @include bp(large) {
      width: 60%;
    }

    &-table {
      thead {
        font-weight: 700;
      }

      tr {
        border-bottom: 1px solid #e5e5e5;
      }

      td {
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      tbody {
        border-bottom: 1px solid #aaa;
      }
    }
  }
}
