.b-faq {
  background-color: map-get($colors, 'secondary');
  color: #fff;

  &-items {
    margin: 0;
  }
}

.b-faq-item__chevron {
  color: map-get($colors, 'primary');

  @include gradient-text(gold);

  transition: $global-transition;
  display: flex;
  align-items: center;
  justify-content: center;
}

.b-faq-item__answer {
  padding-bottom: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: $global-transition;

  @include list-styling();
}

.b-faq-item--open {
  .b-faq-item__chevron {
    transform: rotate(180deg);
  }

  .b-faq-item__answer {
    opacity: 1;
    padding-bottom: $spacing-vertical-mobile;
    max-height: 800px;
  }
}

.b-faq-item__question {
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-bottom: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: normal;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #fff;

    .b-faq-item__chevron {
      color: #fff;
    }
  }
}
