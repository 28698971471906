.b-footer {
  background-color: #f5f5f5;
}

.b-footer__inner {
  padding: 2.4rem 0;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include bp(large) {
    flex-direction: row;
  }
}

.b-footer__copyright {
  border-top: 1px solid #ddd;
  padding-top: 2.4rem;
  margin-top: 2.4rem;
  font-size: 1.6rem;
}

.b-footer__contact-form {
  label {
    font-size: 1.6rem;
  }

  input,
  textarea {
    width: 100%;
    font-family: $font-sans-serif;
    font-size: 1.6rem;
    padding: 0.6rem 1rem;
    border-radius: 0;
    border: 1px solid #aaa;

    &:active,
    &:focus {
      outline: 2px solid map-get($colors, secondary);
      outline-offset: -2px;
    }
  }

  input[type='submit'] {
    background-color: #fff;
    color: map-get($colors, secondary);
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem 1.2rem;
    border-radius: 0;
    border: 2px solid map-get($colors, secondary);
    cursor: pointer;
    transition: $global-transition;

    &:hover,
    &:focus,
    &:active {
      background-color: map-get($colors, secondary);
      color: #fff;
      border: 2px solid map-get($colors, secondary);
    }
  }
}
