@mixin text-big() {
  font-size: 2.1rem;
  line-height: 2.4rem;

  @include bp(medium) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

@mixin text-small() {
  font-size: 1.6rem;
  line-height: 2.4rem;

  @include bp(medium) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

.u-text-big {
  @include text-big;
}
