.b-promo {
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-position: center center;

  @include bp(large) {
    padding-bottom: 148px;
  }

  &__circles {
    width: 100%;
    margin-bottom: $spacing-vertical-mobile;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include bp(large) {
      flex-direction: row;
    }
  }

  &__left {
    width: 100%;

    @include bp(large) {
      width: 50%;
      margin: 15rem 50% 3.2rem 0;
    }
  }

  &__card {
    border: 1px solid map-get($colors, primary);
    border-image: map-get($gradients, gold) 1;
    padding: 2.4rem;
    background: #fff;
    margin-bottom: $spacing-vertical-mobile;

    @include card-shadow();
  }

  &__title {
    @include h1();

    text-align: center;
    color: map-get($colors, secondary);

    @include bp(large) {
      text-align: left;
    }
  }

  &__subtitle {
    text-align: center;

    @include bp(large) {
      text-align: left;
    }
  }

  &__price {
    font-weight: 900;
    font-size: 6rem;
    line-height: 6rem;
    text-align: center;
    margin-top: $spacing-vertical-mobile;

    @include gradient-text(gold);

    @include bp(large) {
      text-align: right;
      margin-right: 20%;
    }
  }

  &__before-price-text {
    @include text-small();
  }

  &__old-price {
    text-decoration: line-through;
    text-align: center;

    @include bp(large) {
      text-align: right;
      margin-right: 20%;
    }
  }

  &__image {
    @include bp(large) {
      position: absolute;
      top: 0;
      right: 0;
      width: 70%;
    }
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: $spacing-vertical-mobile;
    width: 100%;

    @include bp(large) {
      width: 70%;
    }
  }
}
