$font_size_cta: 1.8rem;
$cta_height: 5rem;

.b-call-to-action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: $font_size_cta;
  font-size: $font_size_cta;
  font-weight: 900;
  padding: calc(#{$cta_height} - 2 * #{$font_size_cta}) 2.4rem;
  border: 2px solid;
  cursor: pointer;
  text-decoration: none;

  @include card-shadow();
}

.b-call-to-action--gold {
  background-color: map-get($colors, gold);
  transition: $global_transition;
}

.b-call-to-action--primary {
  background-color: transparent;
  border-color: map-get($colors, primary);
  border-image: map-get($gradients, gold) 1;
  color: map-get($colors, secondary);

  &:hover,
  &:focus,
  &:active {
    background-color: map-get($colors, primary);

    @include gold-background();

    color: map-get($colors, secondary);
  }
}

.b-call-to-action--secondary {
  background-color: transparent;
  border-color: map-get($colors, secondary);
  color: map-get($colors, primary);

  &:hover,
  &:focus,
  &:active {
    background-color: map-get($colors, secondary);
    color: map-get($colors, primary);
  }
}

.b-call-to-action-wrapper--left {
  display: flex;
  justify-content: flex-start;

  @include bp-down(large) {
    width: 100%;
    justify-content: center;
  }
}

.b-call-to-action-wrapper--right {
  display: flex;
  justify-content: flex-end;

  @include bp-down(large) {
    width: 100%;
    justify-content: center;
  }
}

.b-call-to-action-wrapper--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.b-call-to-action--white {
  background-color: transparent;
  border-color: map-get($colors, white);
  color: map-get($colors, white);

  &:hover,
  &:focus,
  &:active {
    background-color: map-get($colors, white);
    color: map-get($colors, secondary);
  }
}

.b-call-to-action--full-gold {
  background-color: transparent;
  border-color: map-get($colors, primary);
  border-image: map-get($gradients, gold) 1;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: map-get($colors, primary);

    @include gold-background();

    color: map-get($colors, secondary);
  }
}
