@mixin list-styling {
  ul {
    list-style: none;
    margin: 0;
    position: relative;

    li {
      padding-left: 1.2rem;
      margin-bottom: 1.2rem;
    }

    li::before {
      content: '·';
      position: absolute;
      left: 0;
      color: map-get($colors, primary);
    }
  }
}

@mixin list-emblem-bullet {
  ul {
    li {
      position: relative;
      padding-left: 3.8rem;
    }

    li::before {
      top: 2px;
      content: '';
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('~assets/images/emblem.png');
    }
  }
}
