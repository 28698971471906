.b-header-promo {
  background-size: contain;
  background-position: center left;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    opacity: 0.5;
  }

  &__product-logo {
    max-height: 20rem;
    object-position: left;
    object-fit: contain;
    margin-bottom: $spacing-vertical-mobile;
  }

  &__product-image {
    width: 100%;
  }

  &__heading {
    z-index: 2;
    color: map-get($colors, primary);
    line-height: 1.2;

    @include gradient-text(gold);
  }

  &__subheading {
    z-index: 2;
    margin-bottom: $spacing-vertical-mobile;
  }

  @include bp(medium) {
    min-height: 30rem;
  }

  @include bp(large) {
    flex-direction: row;

    &__right {
      width: 60%;
    }

    &__left {
      width: 40%;
    }

    &::after {
      display: none;
    }
  }

  @include bp(xlarge) {
    background-size: contain;
  }
}
