.o-container {
  padding: $spacing-vertical-mobile map-get($spacing, small);

  @include bp(medium) {
    &--narrow {
      padding: $spacing-vertical-desktop calc((100% / 12) * 2);
    }

    &--wide {
      padding: $spacing-vertical-desktop calc((100% / 12) * 1);
    }
  }
}
