.c-card-outlined {
  padding: map-get($spacing, medium);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 3.2rem;
  background-color: #fff;
  color: #555;

  &__inner {
    border: 1px solid map-get($colors, primary);
    border-image: map-get($gradients, gold) 1;
    padding: map-get($spacing, small);

    @include bp(medium) {
      padding: map-get($spacing, medium);
    }
  }

  @include list-styling();

  &__name {
    color: map-get($colors, primary);
    line-height: 1.2;

    @include gradient-text(gold);
  }
}

.c-card-outlined--silver {
  @include background-gradient-silver();
}
