.b-badges {
  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__badge {
    width: 35%;

    @include bp-down(medium) {
      &:last-child {
        margin: 0 auto;
      }
    }

    @include bp(medium) {
      width: 25%;
    }
  }
}
