body {
  font-size: 2.1rem;
  line-height: 2.6rem;
  font-family: Lato, sans-serif;
  color: #555;
  overflow: hidden;

  @include bp(large) {
    overflow: initial;
  }
}
