.b-product-features {
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-vertical-mobile;
    position: relative;

    @include bp(large) {
      flex-direction: row;
    }
  }

  &__image {
    margin: 0;
    max-width: 100%;

    @include bp(small) {
      max-width: 60%;
    }

    @include bp(large) {
      position: sticky;
      top: 30%;
      max-width: 40%;
      align-self: flex-start;
    }
  }
}

.c-product-feature {
  padding: map-get($spacing, small);

  &-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid map-get($colors, primary);
    border-image: map-get($gradients, gold) 1;
    padding: map-get($spacing, small);
  }

  &__index {
    font-size: 3.2rem;

    @include gradient-text(gold);

    font-weight: 900;
    margin-right: 3.2rem;
  }
}
