.b-hero-text {
  background: map-get($gradients, blue);

  &__content {
    @include text-big();

    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    color: map-get($colors, white);
    margin-bottom: $spacing-vertical-mobile;

    span {
      display: inline-block;
      opacity: 0;
      filter: blur(4px);
    }
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
