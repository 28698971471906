$underline-color: map-get($colors, primary);

.c-line {
  height: 3px;
  width: 70%;
  margin: 0 auto 3.2rem auto;
  background-color: $underline-color;

  @include gold-background();
}
