.b-image-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-vertical-desktop;
  justify-content: center;
  align-items: center;
  position: relative;

  @include list-styling();

  h2 {
    font-weight: 700;
    color: map-get($colors, secondary);
  }

  &--image-top {
    flex-direction: column-reverse;
  }

  &--image-bottom {
    flex-direction: column;
  }

  @include bp(large) {
    flex-direction: row;

    &--left {
      flex-direction: row;
    }

    &--right {
      flex-direction: row-reverse;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__image,
  &__video {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;

    img {
      object-fit: contain;
      max-height: 70vh;
      max-width: 100%;
    }

    iframe {
      max-height: 60vh;
      width: 100%;
    }
  }

  @include bp(medium) {
    &__image {
      max-width: 40%;
    }
  }

  &--emblem-bullets {
    @include list-emblem-bullet();
  }
}
