.b-horizontal-cards-swiper {
  overflow: hidden;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;

  &-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &-edge {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, #f5f5f5 30%, rgba(#f5f5f5, 0) 100%);
    z-index: 1;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
      background: linear-gradient(270deg, #f5f5f5 30%, rgba(#f5f5f5, 0) 100%);
    }
  }

  &__title {
    z-index: 15;
    position: relative;
  }
}

.c-horizontal-cards-swiper-card {
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 1;

  &__inner {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    transition: $global-transition;
    transform: scale(1);
    background-color: #fff;
    flex-grow: 1;
    height: 100%;

    @include card-shadow();

    &:hover,
    &:focus,
    &:active {
      transform: scale(1.1);
    }
  }

  &__image {
    margin: 0 auto;

    @include bp(small) {
      max-height: 22rem;
    }
  }

  &__content {
    padding: 2.4rem;
    text-align: center;
  }
}

.b-horizontal-cards-swiper__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 3rem;
  opacity: 0.6;
  transition: $global-transition;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  &--next {
    right: 6.4rem;
  }

  &--prev {
    left: 6.4rem;
  }

  &--disabled {
    opacity: 0;
  }
}
