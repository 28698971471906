.b-icon-text-grid {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 3.2rem;

  .grid-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 3.2rem;

    @include bp(medium) {
      gap: 6.4rem;
    }

    &__description {
      width: 100%;
    }
  }

  .grid-item__icon {
    width: 8rem;

    img {
      object-fit: contain;
    }
  }
}
