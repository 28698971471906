.c-plaquette {
  padding: 2.4rem;

  @include background-gradient-silver();

  border: 1px solid #ddd;

  &__inner {
    border: 1px solid #999;
    padding: 2.4rem 3.2rem;
  }

  &__heading {
    text-align: center;
    margin-bottom: 3.2rem;
  }

  @include list-styling();
}
