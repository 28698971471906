.b-image-plaquette {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(xlarge) {
    flex-direction: row-reverse;
  }

  &__badge {
    position: absolute;
    top: -3.2rem;
    right: 1rem;
    width: 30%;

    @include bp(medium) {
      width: 20%;
    }

    @include bp(large) {
      width: 15%;
    }
  }
}
