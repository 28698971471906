.b-hero-text-extendable-content {
  background: map-get($gradients, blue);
  color: #fff;

  &__hero {
    @include text-big();

    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    color: map-get($colors, white);
    margin-bottom: $spacing-vertical-mobile;
    text-align: center;

    span {
      display: inline-block;
      opacity: 0;
      filter: blur(4px);
    }
  }

  &__content {
    &-heading {
      text-align: center;
      margin-bottom: 3.2rem;
    }

    max-height: 0;
    overflow: hidden;
    transition: $global-transition;
    position: relative;
    margin-bottom: 3.2rem;
  }
}

.b-hero-text-extendable-content__button--extend {
  opacity: 1;
  max-height: 1000px;
}

.b-hero-text-extendable-content__button--collapse {
  opacity: 0;
  max-height: 0;
}

.b-hero-text-extendable-content--extended {
  .b-hero-text-extendable-content__button--extend {
    opacity: 0;
    max-height: 0;
  }

  .b-hero-text-extendable-content__button--collapse {
    opacity: 1;
    max-height: 1000px;
  }

  .b-hero-text-extendable-content__content {
    max-height: 30000px;
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
