.b-bibliography {
  color: #999;

  &__items {
    transition: $global-transition;
    max-height: 12000px;
  }

  &__title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: $global-transition;

    &:hover,
    &:active,
    &:focus {
      color: #666;
    }
  }

  &__expand {
    transition: $global-transition;
    transform: rotate(180deg);
  }

  &--closed {
    .b-bibliography__expand {
      transform: rotate(0deg);
    }

    .b-bibliography__items {
      max-height: 0;
      transition: $global-transition;
      overflow: hidden;
    }
  }
}
